.mg20 {

  .btn-choose {
    margin-bottom: 6px;
  }

  .btn-upload {
    padding: 10px;
    width: 100%;
  }

  .file-name {
    margin-bottom: 12px;
  }


}